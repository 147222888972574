import clsx from 'clsx'
import { ChevronDown, ChevronUp, Home, Settings, MessagesSquare, Play, FileType, PlayCircle, GitBranch, Workflow, Box, Settings2, Boxes, SquareTerminal, Database, ListCheck } from "lucide-react"
import { useState } from 'react'

import { useUserSession } from '@/session/UserSession'
import { Button, Separator, Styles } from '@reactik/components'
import { SidebarItem, SidebarSection, useSidebarToggle } from '@reactik/layout'
import { Path, useLocation } from '@reactik/router'
import { Permission } from '@vertesia/common'

import { AnyOf } from './session/permissions/helpers'
import { SecureSidebarItem } from './session/permissions/SecureSidebarItem'
import AccountSelection from './features/accounts/AccountSelection'
import SelectProjectNav from './features/project/SelectProjectNav'
import { isVertesiaEmail } from './utils/email'
import { TooltipPopover } from './components/TooltipPopover'

const { isRootPath } = Path

function matchPathPrefix(path: string, prefix: string) {
    return path.startsWith(prefix)
}

function matchPathPrefixes(path: string, ...prefixes: string[]) {
    return prefixes.some(prefix => path.startsWith(prefix))
}

function matchPathPrefixOrRoot(path: string, prefix: string) {
    return isRootPath(path) || path.startsWith(prefix)
}

interface AppMenuProps {
}
export function AppMenu({ }: AppMenuProps) {
    const { user, typeRegistry, project, onboardingComplete } = useUserSession();
    const [ showContentTypes, setShowContentTypes ] = useState(false);
    const { isOpen } = useSidebarToggle()
    
    const path = useLocation().pathname;

    const handleContentTypesClick = (e: React.MouseEvent) => {
        setShowContentTypes(!showContentTypes)
        e.preventDefault()
        e.stopPropagation()
    }

    const objectsTools = typeRegistry && typeRegistry.types.length > 0
        ? <Button variant='unstyled' onClick={handleContentTypesClick} className="m-0 p-1 rounded-full opacity-50 hover:bg-gray-200 hover:text-indigo-800">{showContentTypes ? <ChevronUp className='size-4' /> : <ChevronDown className='size-4' />}</Button>
        : null

    return (
        <div className="flex flex-col h-full gap-2 py-2">
            <div className="shrink-0 gap-2">
                <AccountSelection />
                <SelectProjectNav />
            </div>

            {project && (<Separator />)}

            {
                project && (
                    <div className="flex-1 min-h-0 overflow-y-auto no-scrollbar">
                        <nav className="flex flex-col gap-2 h-full">
                            <SidebarSection>
                                <SidebarItem
                                    id="menu-dashboard"
                                    current={matchPathPrefixOrRoot(path, '/dashboard')}
                                    href="/studio/dashboard"
                                    icon={Home}
                                >
                                    Dashboard
                                </SidebarItem>
                                {
                                    !onboardingComplete &&
                                    <SidebarItem current={matchPathPrefix(path, '/onboarding')}
                                        href="/studio/onboarding" icon={ListCheck}>Onboarding</SidebarItem>
                                }
                            </SidebarSection>
                            <SidebarSection title='Tasks'>
                                <SidebarItem
                                    id="menu-interactions"
                                    current={matchPathPrefix(path, "/studio/interactions")}
                                    href="/studio/interactions"
                                    icon={MessagesSquare}
                                >
                                    Interactions
                                </SidebarItem>
                                <SidebarItem
                                    id="menu-prompts"
                                    current={matchPathPrefix(path, "/studio/prompts")}
                                    href="/studio/prompts"
                                    icon={SquareTerminal}
                                >
                                    Prompts
                                </SidebarItem>
                                <SidebarItem
                                    id="menu-runs"
                                    current={matchPathPrefix(path, "/studio/runs")}
                                    href="/studio/runs"
                                    icon={Play}
                                >
                                    Runs
                                </SidebarItem>
                            </SidebarSection>
                            <SidebarSection title='Content'>
                                <SidebarItem
                                    id='menu-objets'
                                    current={matchPathPrefixes(path, "/store/objects")}
                                    href="/store/objects" icon={Boxes}
                                    tools={objectsTools}
                                >
                                    Objects
                                </SidebarItem>
                                <ContentTypesMenu isOpen={showContentTypes && isOpen} />
                                <SidebarItem
                                    id="menu-types"
                                    current={matchPathPrefix(path, "/store/types")}
                                    href="/store/types"
                                    icon={FileType}
                                >
                                    Types
                                </SidebarItem>
                                {
                                    isVertesiaEmail(user?.email) && (
                                        <SidebarItem
                                            id="menu-collections"
                                            current={matchPathPrefix(path, "/store/collections")}
                                            href="/store/collections"
                                            icon={Database}
                                        >
                                            Collections
                                        </SidebarItem>
                                    )
                                }
                            </SidebarSection>
                            <SidebarSection title='Workflow'>
                                <SidebarItem
                                    id="menu-executions"
                                    current={matchPathPrefix(path, "/store/executions")}
                                    href="/store/executions"
                                    icon={PlayCircle}
                                >
                                    Executions
                                </SidebarItem>
                                <SidebarItem
                                    id="menu-workflows"
                                    current={matchPathPrefix(path, "/store/rules")}
                                    href="/store/rules"
                                    icon={GitBranch}
                                >
                                    Rules
                                </SidebarItem>
                                <SidebarItem
                                    id="menu-processes"
                                    current={matchPathPrefix(path, "/store/workflows")}
                                    href="/store/workflows"
                                    icon={Workflow}
                                >
                                    Processes
                                </SidebarItem>
                            </SidebarSection>
                            <SidebarSection title='Models'>
                                <SidebarItem
                                    id="menu-environments"
                                    current={matchPathPrefix(path, "/studio/environments")}
                                    href="/studio/environments"
                                    icon={Box}
                                >
                                    Environments
                                </SidebarItem>
                                <SidebarItem
                                    id="menu-training"
                                    current={matchPathPrefix(path, "/studio/training")}
                                    href="/studio/training"
                                    icon={Settings2}
                                >
                                    Fine Tuning
                                </SidebarItem>
                            </SidebarSection>
                            <SidebarSection isFooter>
                                <SecureSidebarItem
                                    id="menu-settings"
                                    current={matchPathPrefix(path, "/settings")}
                                    href="/settings"
                                    icon={Settings}
                                    permission={AnyOf(Permission.project_manage, Permission.account_manage)}
                                >
                                    Settings
                                </SecureSidebarItem>
                            </SidebarSection>
                        </nav>
                    </div>
                )
            }
        </div>
    )
}

interface ContentTypesMenuProps {
    isOpen: boolean
}
function ContentTypesMenu({ isOpen }: ContentTypesMenuProps) {
    const path = useLocation().pathname
    const { typeRegistry } = useUserSession()
    const [filter, setFilter] = useState('')

    if (!isOpen || !typeRegistry?.types.length) {
        return <></>
    }

    const filteredTypes = filter
        ? typeRegistry.types.filter(type => type.name.toLowerCase().includes(filter.toLowerCase()))
        : typeRegistry.types

    return (
        <div className='ml-2 pl-2 pt-2 border-l border-sidebar-border border-solid'>
            <input autoFocus value={filter} onChange={(e: any) => setFilter(e.target.value.trim())} type='text' placeholder="Filter..." className={clsx(Styles.INPUT_UNSTYLED, "text-sm text-sidebar-foreground px-2 py-2 rounded-lg ")} />
            <div className="max-h-96 overflow-y-auto">
                {
                    filteredTypes.map(type => (
                        <SidebarItem key={type.id} current={matchPathPrefix(path, `/store/browse/${type.id}`)} href={`/store/browse/${type.id}`}
                            className='h-auto flex items-start overflow-hidden'>
                            <TooltipPopover description={type.name} size='sm' placement='right'>
                                {type.name.length > 50 ? type.name.substring(0, 50) + '...' : type.name}
                            </TooltipPopover>
                        </SidebarItem>
                    ))
                }
            </div>
        </div>
    )
}
