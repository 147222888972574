import { TriangleAlert } from "lucide-react"
import React, { useRef } from "react"
import { _Modal, _ModalTitle, _ModalFooter } from "./shadcn"
import { Button } from "./shadcn/button"

interface ConfirmModalProps {
    title: string
    content: string | React.ReactNode
    onConfirm: () => void
    onCancel: () => void
    isOpen: boolean
}

export function ConfirmModal({ title, content, onConfirm, onCancel, isOpen }: ConfirmModalProps) {
    const cancelButtonRef = useRef(null)

    return (
        <_Modal isOpen={isOpen} onClose={onCancel} description="Confirm Modal">
            <div className="sm:flex sm:items-start p-2">
                <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <TriangleAlert className="size-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <_ModalTitle className="leading-6" show >
                        {title}
                    </_ModalTitle>
                    <div className="mt-2">
                        <div className="prose text-sm text-gray-500">
                            {content}
                        </div>
                    </div>
                </div>
            </div>
            <_ModalFooter align="right">
                <Button
                    variant="destructive"
                    onClick={onConfirm}
                >
                    Confirm
                </Button>
                <Button
                    variant="outline"
                    onClick={onCancel}
                    ref={cancelButtonRef}
                >
                    Cancel
                </Button>
            </_ModalFooter>
        </_Modal>
    )
}