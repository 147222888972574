import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";

import { cn } from "../libs/utils";
import { Tab, TabsContext as _TabContext } from '../tabs/TabsContext.js';

const TabsContext = React.createContext<{ size?: number }>({ size: undefined });

interface TabsProps {
  current?: string | (() => string);
  /**
   * Return false to prevent the tab from being selected
   * @param tab
   * @returns
   */
  tabs: Tab[];
  defaultValue?: string;
  className?: string;
  fullWidth?: boolean;
  // onSelect?: (tab: Tab) => void | boolean;
  // children?: React.ReactNode | React.ReactNode[];
  // navigate?: (path: string) => void;
}

const _Tabs = ({ tabs, defaultValue, className, fullWidth }: TabsProps) => (
  <TabsPrimitive.Root defaultValue={defaultValue} className={className}>
    <TabsList size={fullWidth ? tabs.length : 0} className={fullWidth ? "w-full" : ""}>
      {tabs.map((tab) => (
        <TabsTrigger key={tab.name} value={tab.name} disabled={tab.disabled}>
          {tab.label}
        </TabsTrigger>
      ))}
    </TabsList>
    {tabs.map((tab) => (
      <TabsContent key={tab.name} value={tab.name} className="w-full px-0">
        {tab.content}
      </TabsContent>
    ))}
  </TabsPrimitive.Root>
);

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & { size?: number }
>(({ className, size, ...props }, ref) => (
  <TabsContext.Provider value={{ size }}>
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        "border-b -mb-px flex space-x-4",
        className
      )}
      {...props}
    />
  </TabsContext.Provider>
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const { size } = React.useContext(TabsContext);
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        "border-b-2 px-1 py-2 text-sm font-medium whitespace-nowrap cursor-pointer",
        "border-transparent text-muted-foreground hover:border-border hover:text-foreground",
        "data-[state=active]:border-primary data-[state=active]:text-primary",
        "disabled:pointer-events-none disabled:opacity-50",
        className,
        size ? `w-1/${size}` : ""
      )}
      {...props}
    />
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-4 focus-visible:outline-none",
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { _Tabs, TabsList, TabsTrigger, TabsContent };