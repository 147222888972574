import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip"

import { cn } from "../libs/utils"
import { Loader2 } from "lucide-react"

const buttonVariants = cva(
  "hover:cursor-pointer inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        destructive:
          "bg-destructive dark:bg-destructive/10 text-destructive-foreground ring-1 ring-inset ring-destructive-muted/50 dark:ring-destructive-muted/50 shadow-xs hover:bg-destructive/50",
        outline:
          "border border-input bg-background shadow-xs hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-xs hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-white underline-offset-4 hover:underline",
        primary:
          "bg-primary text-white shadow-xs hover:bg-primary/90",
        soft: "bg-primary/5 dark:bg-primary/10 text-primary shadow-xs hover:bg-primary/10 dark:hover:bg-primary/20",
        unstyled:
          ""
      },
      size: {
        xs: 'h-7 rounded px-2 py-1 text-xs gap-x-1',
        sm: "h-8 rounded px-2 text-xs",
        md: "h-9 rounded-md px-4 py-2",
        lg: "h-10 rounded-md px-3",
        xl: 'rounded-md px-3.5 py-2.5 text-sm gap-x-2',
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
  alt?: string
  title?: string
  isDisabled?: boolean
  isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, alt, isDisabled, isLoading, title, onClick, type, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    const buttonElement = (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        disabled={isDisabled || isLoading || props.disabled}
        ref={ref}
        onClick={onClick}
        type={type}
        autoFocus={false}
        {...props}
      >
        {isLoading && <Loader2 className="animate-spin" />}
        {props.children}
      </Comp>
    )

    if (alt || title) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              {buttonElement}
            </TooltipTrigger>
            <TooltipContent style={{ zIndex: 100 }}>
              <p className="text-white">{alt || title}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }

    return buttonElement
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }