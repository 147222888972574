const env = import.meta.env;

const isLocalDev = window.location.host.includes("localhost");
const urlParams = new URLSearchParams(window.location.search);

const host = window.location.host.split(".");
const backendBase = urlParams.get("d") ?? env.VITE_API_BACKEND_BASE;

/**
 * Under which condition the unified API gateway can be used.
 *
 * We have to validate the env, e.g. "production", because the API backend base is also
 * set to "api.vertesia.io" in the development environments, but it has a different meaning
 * there.
 */
type UnifiedApiMatchingFn = (env: string) => boolean;
const unifiedApiBackends: Record<string, UnifiedApiMatchingFn> = {
    "api.vertesia.io": (env: string) => env === "production",
    "api-preview.vertesia.io": (env: string) => env === "preview",
    "api-staging.vertesia.io": (env: string) => env === "staging",
    "api-poc-mckesson.vertesia.io": (env: string) => env === "poc-mckesson",
};

// Production detection logic to handle tenants
const isProd = () => {
    if (host.length === 3 && host[0] === "cloud") {
        return window.location.host.endsWith(".vertesia.io") || window.location.host.endsWith(".becomposable.com");
    }
    if (host.length === 4 && ["staging", "preview"].includes(host[0])) {
        return false;
    }
    if (host.length === 4) {
        return host[1] === "cloud";
    }
    return false;
};

const getDatacenter = () => {
    const hasDCSelectedInDomain = ["gcp", "aws"].includes(host[1]);
    const dcInParams = urlParams.has("dc");
    if (!hasDCSelectedInDomain && !dcInParams) {
        return null;
    }

    return hasDCSelectedInDomain ? host[1] : urlParams.get("dc");
};

const getEnv = () => {
    if (isProd()) {
        return "production";
    }

    if (isLocalDev) {
        return env.VITE_BACKEND_ENV ? env.VITE_BACKEND_ENV : "development";
    }

    if (window.location.host.includes("preview.cloud.")) {
        return "preview";
    }

    if (window.location.host.includes("staging.cloud.")) {
        return "staging";
    }

    if (window.location.host == "mckesson.vertesia.app") {
        return "poc-mckesson";
    }

    const gitRef = __VERCEL_ENV_COMMIT_REF__;
    console.debug("Git ref:", gitRef);
    if (gitRef && gitRef !== "main" && gitRef !== "preview") {
        return "dev-" + gitRef.replace(/[^a-zA-Z0-9]/g, "-");
    }

    return "development";
};

const setFavicon = (env: string) => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
    }

    switch (env.toLowerCase()) {
        case "production":
            link.href = "/Vertesia-Icon-White.svg";
            return;
        case "preview":
            link.href = "/Vertesia-Icon-Purple.svg";
            return;
        case "staging":
            link.href = "/Vertesia-Icon-Teal.svg";
            return;
        default:
            link.href = "/Vertesia-Icon-Blue.svg";
    }
};

const envType = getEnv();
if (!envType) {
    throw new Error("Could not determine environment type from hostname: " + window.location.host);
} else {
    console.log("Environment is:", envType);
    setFavicon(envType);
}

const getServerUrl = (service: string, env: string) => {
    //const params = useParams();

    if (isLocalDev) {
        if (backendBase) {
            return `https://${service}-server-${env}.${backendBase}`;
        } else {
            if (service === "studio") {
                return `http://127.0.0.1:8091`;
            }
            if (service === "zeno") {
                return `http://127.0.0.1:8092`;
            }
        }
    }

    if (!backendBase) {
        throw new Error("VITE_API_BACKEND_BASE not set");
    }

    const datacenter = getDatacenter() ?? "gcp";
    let serverUrl: string;

    // Unified API Gateway is only usable in some conditions
    const canUseUnifiedApi = datacenter == "gcp"
        && unifiedApiBackends[backendBase]
        && unifiedApiBackends[backendBase](env);
    if (canUseUnifiedApi) {
        const site = backendBase;
        serverUrl = `https://${site}`;
    } else {
        const suffix = getDatacenter() ? `${datacenter}.${backendBase}` : backendBase;
        serverUrl = `https://${service}-server-${env}.${suffix}`;
    }

    console.log(`Server URL for ${service} is: ${serverUrl}`);
    return serverUrl;
};

const getBaseUrl = () => {
    if (env.BASE_URL) {
        return env.BASE_URL;
    }

    return `https://${window.location.host}`;
};

function getAuthDomain() {
    if (isLocalDev && typeof window !== "undefined") {
        // use current host since vite may change the port
        console.log("Using local dev auth domain:", window.location.host);
        return window.location.host;
    }

    return window.location.host;
}

const authDomain = getAuthDomain();

let isDatadogRumEnabled;

if (env.VITE_DATADOG_RUM_ENABLED) {
    // override with env var always take precedence
    isDatadogRumEnabled = env.VITE_DATADOG_RUM_ENABLED === "true";
} else if (isLocalDev) {
    // disable RUM by default in local dev
    isDatadogRumEnabled = false;
} else {
    // enable RUM by default in cloud envs
    isDatadogRumEnabled = true;
}

const getTenantName = () => {
    if (isLocalDev) {
        return undefined;
    }

    // Production: customerone.cloud.vertesia.io (4 parts)
    if (isProd() && host.length === 4) {
        console.log("Production host:", host);
        return host[0];
    }

    // Preview/Staging: customerone.preview.cloud.vertesia.io (5 parts)
    if (!isProd() && host.length === 5 && (host[1] === "preview" || host[1] === "staging")) {
        console.log("Preview/Staging host:", host);
        return host[0];
    }
    console.log("Unknown host:", host);
    return undefined;
};

const Env = {
    version: __VERCEL_ENV_COMMIT_SHA__,
    isProd: isProd(),
    isDev: !isProd(),
    isPreview: envType === "preview",
    isLocalDev,
    isDocker: __HOSTING_TYPE__ === "docker",
    type: envType,
    BASE_URL: getBaseUrl(),
    endpoints: {
        zeno: getServerUrl("zeno", envType),
        studio: getServerUrl("studio", envType),
    },
    API_PATH: `/api/v1`,
    firebase: {
        apiKey: env.VITE_FIREBASE_API_KEY,
        authDomain,
        projectId: "dengenlabs",
        tenantName: getTenantName(),
    },
    datadog: isDatadogRumEnabled,
};

if (!Env.isProd) {
    console.log("Initializing App with Env", Env);
}

export default Env;
