import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "./libs/utils"

// Base badge variants
const badgeVariants = cva(
  "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium",
  {
    variants: {
      variant: {
        neutral: "bg-neutral dark:bg-neutral/10 text-neutral-foreground",
        destructive: "bg-destructive dark:bg-destructive/10 text-destructive-foreground",
        attention: "bg-attention dark:bg-attention/10 text-attention-foreground",
        success: "bg-success dark:bg-success/10 text-success-foreground",
        info: "bg-info dark:bg-info/10 text-info-foreground",
        done: "bg-done dark:bg-done/10 text-done-foreground",
        accent2: "bg-accent2 dark:bg-accent2/10 text-accent2-foreground",
        accent3: "bg-accent3 dark:bg-accent3/10 text-accent3-foreground",
        accent4: "bg-accent4 dark:bg-accent4/10 text-accent4-foreground",
      },
    },
    defaultVariants: {
      variant: "neutral",
    },
  }
)

// Base Badge props interface
interface BaseBadgeProps
  extends React.HTMLAttributes<HTMLSpanElement>,
  VariantProps<typeof badgeVariants> {
  children: React.ReactNode;
  onClick?: () => void;
}

// Base Badge component
export function Badge({ className, variant, children, onClick, ...props }: BaseBadgeProps) {
  return (
    <span
      className={cn(badgeVariants({ variant }), className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </span>
  )
}

// Maintain existing component names using the new base Badge
export function GrayBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="neutral" onClick={onClick}>{children}</Badge>
}

export function RedBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="destructive" onClick={onClick}>{children}</Badge>
}

export function YellowBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="attention" onClick={onClick}>{children}</Badge>
}

export function GreenBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="success" onClick={onClick}>{children}</Badge>
}

export function BlueBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="info" onClick={onClick}>{children}</Badge>
}

export function IndigoBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="accent2" onClick={onClick}>{children}</Badge>
}

export function PurpleBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="done" onClick={onClick}>{children}</Badge>
}

export function PinkBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="accent3" onClick={onClick}>{children}</Badge>
}

export function TealBadge({ children, onClick }: Omit<BaseBadgeProps, 'variant'>) {
  return <Badge variant="accent4" onClick={onClick}>{children}</Badge>
}

const dotBadgeVariants = cva(
  // Base styles
  "inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-border",
  {
    variants: {
      variant: {
        success: "",
        destructive: "",
        attention: "",
        done: "",
        neutral: "",
        info: "",
        accent2: "",
        accent3: "",
        accent4: "",
      }
    },
    defaultVariants: {
      variant: "neutral"
    }
  }
);

// Separate dot variants to allow className overrides
const dotVariants = cva("h-1.5 w-1.5", {
  variants: {
    variant: {
      success: "fill-success-foreground",
      destructive: "fill-destructive-foreground",
      attention: "fill-attention-foreground",
      done: "fill-done-foreground",
      neutral: "fill-neutral-foreground",
      info: "fill-info-foreground",
      accent2: "fill-accent2-foreground",
      accent3: "fill-accent3-foreground",
      accent4: "fill-accent4-foreground",
    }
  },
  defaultVariants: {
    variant: "neutral"
  }
});

interface DotBadgeProps 
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof dotBadgeVariants> {
  children?: React.ReactNode;
  // Add specific dot className prop for backward compatibility
  dotClassName?: string;
}

export function DotBadge({ 
  variant, 
  className,
  dotClassName, // New prop for dot-specific classes
  children,
  ...props 
}: DotBadgeProps) {
  return (
    <span 
      className={cn(dotBadgeVariants({ variant }), className)}
      {...props}
    >
      <svg 
        className={cn(dotVariants({ variant }), dotClassName)}
        viewBox="0 0 6 6" 
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3}  />
      </svg>
      {children}
    </span>
  );
}
